<template>
    <el-dialog :title="`${ruleForm.id?'编辑':'添加'}任务`" :visible.sync="addVisible" width="650px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="任务标题" prop="taskName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.taskName"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务描述" prop="taskDescription" :rules="rules.blurRule">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="ruleForm.taskDescription">
          </el-input>
        </el-form-item>
        
        <el-form-item label="任务类型" prop="taskType" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.taskType"
            placeholder="类型"
          >
            <el-option
              v-for="(item,index) in taskTypesOptions"
              :key="index"
              :label="item.text"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推荐标签" prop="tagId" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.tagId"
            placeholder="标签"
          >
            <el-option
              v-for="(item,index) in taskLabelsOptions"
              :key="index"
              :label="item.tagName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务图标" prop="taskIcon" :rules="rules.typeIcon">
          <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.taskIcon}]"></uploadImg>
        </el-form-item>
        <div class="flex">
          <el-form-item label="循环" prop="loopTask" :rules="rules.changeRule">
            <el-radio-group v-model="ruleForm.loopTask">
              <el-radio :label="0">一次性</el-radio>
              <el-radio :label="1">循环</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" label-width="0" prop="loopRound" :rules="rules.blurRule" v-if="ruleForm.loopTask">
            <el-input placeholder="请输入内容" v-model.number="ruleForm.loopRound">
              <template slot="append">天数</template>
            </el-input>
          </el-form-item>
        </div>
        
        <!-- <el-form-item label="完成时间" prop="finishTime" :rules="rules.changeRule">
          <el-input
            v-model="ruleForm.finishTime"
            style="width: 300px !important"
            placeholder="请输入内容"
            class="input-with-select"
          >
            <template #append>
              <el-select v-model="ruleForm.finishTimeUnit" placeholder="请选择" style="width: 115px !important">
                <el-option label="分钟" value="minutes" />
                <el-option label="小时" value="hours" />
                <el-option label="天" value="days" />
              </el-select>
            </template>
          </el-input>
        </el-form-item> -->
        <el-form-item label="最大人数">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.maxUser"
            
          ></el-input>
        </el-form-item>
        <el-form-item label="任务等级" prop="taskLevel" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.taskLevel"
            placeholder="类型"
          >
            <el-option :value="1">1</el-option>
            <el-option :value="2">2</el-option>
            <el-option :value="3">3</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推荐白名单">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="填写房间ID，任务发布后任务会直接下发给白名单内里的房间。下发任务不受任务限制影响。"
            v-model="ruleForm.whiteList">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeFunc">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
import uploadImg from '@/components/uploadImg'
export default {
  props: {
    dataRow: {
      type: Object,
      default: () => {},
    },
    addVisible:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      totalNum: 0,
      ruleForm: {},
      taskTypesOptions: [
        { text: '限时任务', value: 0 }, 
        { text: '常规任务', value: 1 }, 
        { text: '推广任务', value: 2 }
      ],
      taskLabelsOptions: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg},
  watch: {
    dataRow(val) {
      if (val.id) {
        this.ruleForm = { ...val };
      } else {
        this.ruleForm = {status:0};
      }
    },
  },
  created() {
    this.getTagList();
  },
  methods: {
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.taskIcon=val[0].url
      }
    },
    async submitFunc() {
      console.log(this.ruleForm)
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.taskSave({...this.ruleForm});
          if (res) {
            this.closeFunc();
            this.$message.success("操作成功！");
            this.$emit('refresh');
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTagList() {
      let res = await this.$http.taskTagList({pageNo:1,pageSize:100});
      if (res) {
        this.taskLabelsOptions = res.result.data;
      }
    },
    closeFunc(){
       this.$emit('close') 
    }
  },
};
</script>

<style scoped>
.flex{
  display: flex;
  align-items: center;
  gap: 30px;
}
</style>
